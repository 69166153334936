/* Classes that can be used inside a LobPage's content */

.lobpage-video {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 24px;
  }
  
  .lobpage-video iframe {
    margin-left: auto;
    margin-right: auto;
  }
  
  .lobpage-image {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .lobpage-image img {
    margin-left: auto;
    margin-right: auto;
  }
  
  @media (orientation: portrait)  {
    .lobpage-video iframe {
      width: 100%;
      aspect-ratio: 9 / 16;
    }
  
    .lobpage-image img {
      width: 100%;
    }  
  }
  
  @media (orientation: landscape) {
    .lobpage-video iframe {
      width: 50%;
      aspect-ratio: 16 / 9;
    }
  
    .lobpage-image img {
      width: 50%;
    }
  }
  