.markdown {
    padding-top: 8px;
    * {
        overflow-wrap: break-word;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }

    h1 {
        font-weight: 700;
        font-size: 2.25rem;
        line-height: 1.5em;
    }

    h2 {
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.3em;
    }

    h2 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1em;
    }

    h1, h2, h3 {
        font-family: "Roboto Slab", "Times New Roman", serif !important;
        margin-top: 20px;
        margin-bottom: 10px;
    }
}
