.Loading {
    .pulse {
        animation: pulse-animation 1s infinite;
    }
      
    @keyframes pulse-animation {
        0% {
            box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
        }
        100% {
            box-shadow: 0 0 0 40px rgba(0, 0, 0, 0);
        }
    }
}
